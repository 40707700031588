import dayjs from 'dayjs'
import { ActivityLog } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import IconInboundCall from 'src/icons/IconInboundCall'
import IconMissedCall from 'src/icons/IconMissedCall'
import IconOutboundCall from 'src/icons/IconOutboundCall'
import { buildFullName } from 'src/lib/formatters'

interface CallLogCardProps {
  activityLog: ActivityLog
}

const MiniCallLog = ({ activityLog }: CallLogCardProps) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isInboundCall = activityLog.callDirection === 'INBOUND'
  const isVirtualCall = activityLog.callType === 'BLANDAI'
  const label = isOutboundCall ? 'Outgoing Call' : 'Incoming Call'
  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const isManualRegistered = activityLog.callStatus === null
  const voiceMailTitle = activityLog.voiceMailTemplate?.title

  return (
    <div
      className={`border-bg-doubleNickel-gray-600 flex w-full flex-col gap-2 rounded-lg bg-doubleNickel-gray-50 p-4`}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-2 text-sm font-semibold text-doubleNickel-gray-700">
          {label}
          {isOutboundCall && !isMissedCall && (
            <IconOutboundCall
              className="h-4 w-4"
              stroke="#079455"
              fill="#FFF"
            />
          )}
          {isInboundCall && !isMissedCall && (
            <IconInboundCall className="h-4 w-4" />
          )}
          {isMissedCall && <IconMissedCall className="h-4 w-4" />}
        </div>
        {isMissedCall && (
          <StyledBadge textTransform="capitalize" color="red">
            Missed
          </StyledBadge>
        )}
        {isVirtualCall && (
          <StyledBadge textTransform="capitalize" color="blue">
            Virtual
          </StyledBadge>
        )}
      </div>
      <div
        className={`flex w-full flex-row justify-between text-sm font-medium text-doubleNickel-gray-700`}
      >
        {isManualRegistered && <div>Manually registered</div>}
        {!voiceMailTitle && !isMissedCall && !isNaN(callInMinutes) && (
          <p>
            {callInMinutes} minutes, {remindingSeconds} seconds
          </p>
        )}
        {isLeftVoiceMail && (
          <div className="flex flex-row">
            <p>Left voicemail</p>
            {voiceMailTitle && <p>: {voiceMailTitle}</p>}
          </div>
        )}
      </div>
      <div
        className={`flex w-full flex-row justify-between text-xs text-doubleNickel-gray-500`}
      >
        {dayjs(activityLog.activityDate).isValid() && (
          <div>
            {dayjs(activityLog.activityDate).format('MM/DD/YYYY hh:mm A')}
          </div>
        )}
        <div>{buildFullName(activityLog.employee)}</div>
      </div>
    </div>
  )
}

export default MiniCallLog
