import { ReactNode } from 'react'

import { useAuth } from 'src/auth'
import { RoleType } from 'src/graphql/types/employees'

const AdminContainer = ({
  ignoreIsAdmin,
  children,
}: {
  ignoreIsAdmin?: boolean
  children: ReactNode
}) => {
  const { currentUser } = useAuth()
  const isAdmin = currentUser?.roles?.includes(RoleType.ADMIN)
  if (ignoreIsAdmin || isAdmin) {
    return children
  }
  return null
}

export default AdminContainer
